<template>
    <div class="index">
        <img src="http://img.fuqz.cc/background1.jpg" class="ass">
        <!-- 头部分 -->
        <div class="backimg" id="backimg">
            <!-- 头部 -->
            <div class="header">
                <ul class="header-ul">
                    <li class="li-one">
                        <a href="javascript:void(0)" @click="Jumphome()"><svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-icon_home"></use>
                        </svg><span class="font">首页</span></a>
                    </li>
                    <li class="fenlei">
                        <a href="javascript:void(0)" @click="JumpArticle()"><svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-category1"></use>
                        </svg><span class="font">文章</span></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="Jumpfriend()"><svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-youlianguanli"></use>
                        </svg><span class="font">友链</span></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="JumpJournal()"><svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-shijianzhou"></use>
                        </svg><span class="font">时间轴</span></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="JumpPhoto()"><svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-zhaopianqiang"></use>
                        </svg><span class="font">照片墙</span></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="JumpLife()"> <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-shuoshuo-"></use>
                        </svg><span class="font">说说</span></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="JumpMessage()"><svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-note"></use>
                        </svg><span class="font">留言</span></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="Jumpabout()"><svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-guanyuwomen"></use>
                        </svg><span class="font">关于</span></a>
                    </li>
                </ul>
            </div>
            <!-- 首页头部背景 -->
            <transition :name="transitionName">
                <router-view></router-view>
            </transition>
        </div>
        <div class="blog-body">
            <!-- 轮播图，是否显示在vuex中存储 -->
              <Rotation v-if="this.$store.state.isRotation"></Rotation>
            <!-- 内容部分-->
            <div class="bo">
                <div class="content">
                    <!-- 左侧-->
                    <div  class="left-con animated bounceInLeft" :class="{'nons':this.$store.state.isLeftShow}">
<!--                    <div  class="left-con" v-if="this.$store.state.isLeftShow">-->
                        <!-- 个人介绍 -->
                        <div class="myself">
                           <div class="touxiang"></div>
                            <ul class="views">
                                <li class="views-label">
                                    <span>{{article}}</span><br/>
                                    <small>文章</small>
                                </li>
                                <li class="views-label">
                                    <span>{{message}}</span><br/>
                                    <small>留言</small>
                                </li>
                                <li class="views-label">
                                    <span>{{label}}</span><br/>
                                    <small>标签</small>
                                </li>
                            </ul>
                        </div>
                        <!-- 天气 -->
                        <div class="weather">
                            <h4> <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-xinqing1"></use>
                            </svg>今日心情~</h4>
                            <div class="temperature">
                                <span>{{temperature}}℃ &nbsp;&nbsp;</span>
                                <span><a href="https://www.qweather.com/">&nbsp;{{city}}</a> | </span>
                                <span>{{desc}} </span>
                            </div>
                            <div class="address">
                                <p><span>您的IP:&nbsp;&nbsp;&nbsp;&nbsp;</span>{{ip}}</p>
                                <p><span>您的地址:&nbsp;&nbsp;</span>{{specificCity}}</p>
                            </div>
                            <div class="reminder">
                                   <span> {{reminder}}</span>
                            </div>
                        </div>
                        <!-- 标签云 -->
                        <div class="tagcloud"l>
                            <div class="tagfont">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-bianpinghuatubiaosheji-1"></use>
                                </svg>标签云
                            </div>
                            <div class="tagsums">
                                <a href="javascript:void(0)" @click="JumpArticles(value.labelName)"v-for="(value,index) in tabNum">{{value.labelName}}</a>
                            </div>
                        </div>
                        <!-- 最新留言-->
                        <div class="message">
                            <div class="zuixin">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-liuyan"></use>
                                </svg>最新留言</div>
                            <!-- 最新留言 -->
                            <ul>
                                <li v-for="(value,index) in newMessage">
                                    <img src="http://img.fuqz.cc/head.jpg" alt="">
                                    <div class="message-content">
                                        <div class="message-top">
                                            <span class="message_user">{{value.messageUser}}</span>
                                            <span class="message_time">{{value.messageDt}}</span>
                                        </div>
                                        <div class="message-bottom">
                                            <a href="javascript:void(0)" @click="JumpMessage()">{{value.messageContent}}</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- 右侧 -->
                    <router-view name="rightbody"></router-view>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <div class="bottom-bar">
            <div class="info">
                <p class="days">
                    <span> 我的客栈已营业：{{days}}</span>
                </p>
                <p class="record">
                    <span><a href="https://beian.miit.gov.cn" target="_blank">豫ICP备2024099713号</a></span>
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>
<script>
    import defaultContent from "@/views/home/defaultContent"
    import headerImg from "@/views/header/headerImg"
    import Rotation from "@/views/home/Rotation"
    export default {
        name: "index",
        components:{
            defaultContent,
            headerImg,
            Rotation,
        },
        watch: {
            /* 路由跳转动画 */
            '$route' (to, from) {
                this.transitionName ='slide-right'
            },
        },
        data(){
            return{
                transitionName:'',
                text:"人在江南，心在江南。",  /*主页文字 */
                images:"http://img.fuqz.cc/113958-153525479855be.jpg", /* 首页图片 */
                article:"",  /* 文章数 */
                label:"",  /* 标签 */
                message:"",  /* 评论 */
                city:"", /* 城市 */
                specificCity:"",  /* 具体位置 */
                ip:"",  /* ip */
                desc:"",  /* 详情 */
                temperature:"",  /* 温度*/
                reminder:"", /* 温馨提示 */
                days:'', /* 计时器*/
                path:"",
                /* 标签 */
                tabNum:[],
                /* 留言 */
                newMessage:[],
            }
        },
        watch:{
            $route(to,from){
                /* 当操作浏览器自带返回键返回主页时左侧栏被掩藏,此时监听主页路由将左侧显示*/
                if(to.path=='/headerImg'){
                    this.$store.commit("updateLeftShow",false)
                }
            }
        },
        methods:{
            /* 获取天气 */
            async findWeather(){
                /* 获取ip */
                const ips =  await this.$External.findIp(null)
                this.ip = ips.ip
                /* 获取系统 */
                var systems = this.findSystem()
                /* 获取浏览器 */
                var browser = this.findBrowser()
                /* 根据ip获取天气*/
                const res = await this.$home.findWeather({"ip":this.ip,"systems":systems,"browser":browser})
                this.city = res.data.city
                this.desc = res.data.info
                this.specificCity = res.data.specificCity
                this.temperature = res.data.temperature
            },
            /* 获取天气 */
            findSystem(){
                var u = navigator.userAgent;
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if(isAndroid){
                    return "Android"
                }else if(isiOS){
                    return "IOS"
                }else{
                    return "windows"
                }
            },
            findBrowser(){
                var is360 = false;
                var isIE = false;
                var isFirefox = false;
                var isChrome = false;
                var isEdge = false;
                var broName = 'Runing';
                var str = '';
                var strStart = 0;
                var strStop = 0;
                var arr = new Array();
                var temp = '';
                // userAgent（用户代理，指浏览器）
                var userAgent = window.navigator.userAgent; //包含以下属性中所有或一部分的字符串：appCodeName,appName,appVersion,language,platform
                //FireFox
                if (userAgent.indexOf('Firefox') != -1) {
                    isFireFox = true;
                    /*broName = 'FireFox浏览器';*/
                    strStart = userAgent.indexOf('Firefox');
                    temp = userAgent.substring(strStart);
                    broName = temp.replace('/', '版本号')

                }
                //Edge
                if (userAgent.indexOf('Edge') != -1) {
                    isEdge = true;
                    /*broName = 'Edge浏览器';*/
                    strStart = userAgent.indexOf('Edge');
                    temp = userAgent.substring(strStart);
                    broName = temp.replace('/', '版本号');
                }
                //IE浏览器
                if (userAgent.indexOf('NET') != -1 && userAgent.indexOf("rv") != -1) {
                    isIE = true;
                    /*broName = 'IE浏览器'; */
                    strStart = userAgent.indexOf('rv');
                    strStop = userAgent.indexOf(')');
                    temp = userAgent.substring(strStart, strStop);
                    broName = temp.replace('rv', 'IE').replace(':', '版本号');
                }

                //360极速模式可以区分360安全浏览器和360极速浏览器
                if (userAgent.indexOf('WOW') != -1 && userAgent.indexOf("NET") < 0 && userAgent.indexOf("Firefox") < 0) {
                    if(navigator.javaEnabled()){
                        is360 = true;
                        broName = '360安全浏览器-极速模式';
                    }else{
                        is360 = true;
                        broName = '360极速浏览器-极速模式';
                    }
                }
                //360兼容
                if (userAgent.indexOf('WOW') != -1 && userAgent.indexOf("NET") != -1 && userAgent.indexOf("MSIE") != -1 && userAgent.indexOf("rv") < 0) {
                    is360 = true;
                    broName = '360兼容模式';
                }
                //Chrome浏览器
                if (userAgent.indexOf('WOW') < 0 && userAgent.indexOf("Edge") < 0) {
                    isChrome = true;
                    /*broName = 'Chrome浏览器';*/
                    strStart = userAgent.indexOf('Chrome');
                    strStop = userAgent.indexOf(' Safari');
                    temp = userAgent.substring(strStart, strStop);
                    broName = temp.replace('/', '版本号');
                }
                return broName
            },
            /* 获取时间 */
            getTimeState(){
                // 获取当前时间
                let timeNow = new Date();
                // 获取当前小时
                let hours = timeNow.getHours();
                // 判断当前时间段
                if (hours >= 0 && hours <= 9) {
                    this.reminder = '早上好!今天又是充满希望的一天~'
                } else if (hours >= 10 && hours <= 13) {
                    this.reminder= '中午好,要适当午休哦~!'
                } else if (hours >= 14 && hours <= 17) {
                    this.reminder= '每天攒下一点快乐，你就是世界上最幸福的人,下午好!'
                } else if (hours >= 18 && hours <= 24) {
                    this.reminder= '晚上好，要早点休息哦~!'
                }
            },
            /* 计算运行时间 */
            runtime(){
                // 初始时间，日/月/年 时:分:秒
                const X = new Date("03/23/2021 20:00:00");
                const Y = new Date();
                const T = (Y.getTime()-X.getTime());
                const M = 24*60*60*1000;
                const a = T/M;
                const A = Math.floor(a);
                const b = (a-A)*24;
                const B = Math.floor(b);
                const c = (b-B)*60;
                const C = Math.floor((b-B)*60);
                const D = Math.floor((c-C)*60);
                //信息写入到DIV中
               this.days = +A+" 天 "+B+" 小时 "+C+" 分 "+D+" 秒 "
            },
            /* 跳转友链,隐藏轮播图、跳转路由 */
            Jumpfriend(){
                /* 隐藏轮播图 */
                this.$store.commit("updateRotation",false)
                /* 隐藏左侧 */
                this.$store.commit("updateLeftShow",true)
                /* 路由跳转 */
                this.$router.push("/friendImg")
            },

            /* 跳转说说 */
            JumpLife(){
                /* 隐藏轮播图 */
                this.$store.commit("updateRotation",false)
                /* 隐藏左侧 */
                this.$store.commit("updateLeftShow",true)
                /* 路由跳转 */
                this.$router.push("/lifeImg")
            },
            /* 跳转照片墙 */
            JumpPhoto(){
                /* 隐藏轮播图 */
                this.$store.commit("updateRotation",false)
                /* 隐藏左侧 */
                this.$store.commit("updateLeftShow",true)
                /* 路由跳转 */
                this.$router.push("/photoImg")
            },
            /* 跳转关于我 */
            Jumpabout(){
                // /* 隐藏轮播图 */
                // this.$store.commit("updateRotation",false)
                // /* 隐藏左侧 */
                // this.$store.commit("updateLeftShow",true)
                /* 路由跳转 */
                this.$router.push("/aboutImg")
            },
            JumpJournal(){
                /* 隐藏轮播图 */
                this.$store.commit("updateRotation",false)
                /* 显示左侧*/
                this.$store.commit("updateLeftShow",true)
                /* 路由跳转 */
                this.$router.push("/journalImg")
            },
            /* 跳转到留言板  */
            JumpMessage(){
                /* 隐藏轮播图 */
                this.$store.commit("updateRotation",false)
                /* 隐藏左侧*/
                this.$store.commit("updateLeftShow",true)
                /* 路由跳转 */
                this.$router.push("/messageImg")
            },
            /* 跳转首页 */
            Jumphome(){
                /* 显示轮播图 */
                this.$store.commit("updateRotation",true)
                /* 显示左侧*/
                this.$store.commit("updateLeftShow",false)
                /* 路由跳转 */
                this.$router.push("/headerImg")
            },
            /* 跳转文章 */
            JumpArticle(){
                /* 显示轮播图 */
                this.$store.commit("updateRotation",false)
                /* 隐藏左侧 */
                this.$store.commit("updateLeftShow",true)
                /* 路由跳转 */
                this.$router.push("/articleImg")
            },
            /* 跳转文章带标签 */
            JumpArticles(value){
                /* 显示轮播图 */
                this.$store.commit("updateRotation",false)
                /* 隐藏左侧 */
                this.$store.commit("updateLeftShow",true)
                /* 修改文章页选中的标签 */
                this.$store.commit("updatelabelName",value)
                /* 路由跳转 */
                this.$router.push("/articleImg")
            },
            /* 获取总览数据 */
            async findOverview(){
                const res = await this.$home.findOverview()
                this.article = res.data.content
                this.label = res.data.label
                this.message = res.data.message
            },
            /* 获取所有标签 */
            async findLabelAll(){
                const res = await this.$home.findLabelAll()
                this.tabNum = res.data
            },
            /* 获取最新留言 */
            async findNewMessage(){
                const res = await this.$home.findNewMessage()
                this.newMessage = res.data
            },
        },
        created() {
            // if (sessionStorage.getItem("store") ) {
            //     this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
            // }
            // //在页面刷新时将vuex里的信息保存到sessionStorage里
            // window.addEventListener("beforeunload",()=>{
            //     sessionStorage.setItem("store",JSON.stringify(this.$store.state))
            // })
            this.getTimeState()
            this.findWeather()
            this.findOverview()
            this.findLabelAll()
            this.findNewMessage()
        },
        mounted(){
            /* 运行时间统计*/
            setInterval(() => {
                this.runtime();
            }, 1000);

            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', function () {
                // console.log("当前路由"+this.$route.href)
                // history.pushState(null, null, document.URL);
            });
            // var userAgentInfo = navigator.userAgent;
            //         var Agents = ["Android", "iPhone",
            //                         "SymbianOS", "Windows Phone",
            //                         "iPad", "iPod"];
            //         var flag = true;
            // for (var v = 0; v < Agents.length; v++) {
            //                  if (userAgentInfo.indexOf(Agents[v]) > 0) {
            //                         flag = false;
            //                         break;
            //                      }
            //              }
            //
            //              var ua = navigator.userAgent.toLowerCase();
            //              if(ua.indexOf('micromessenger') != -1) {
            //                     alert(true)
            //                 } else {
            //                     alert(false)
            //                 }
        }
    }
</script>
