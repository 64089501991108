<template xmlns:http="http://www.w3.org/1999/xhtml">
    <div class="Rotation">
        <!-- 最新推荐 -->
        <div class="latest">
            <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-tuijian1"></use>
            </svg><span>最新推荐</span>
        </div>
        <!-- 轮播图 -->
        <div class="chart" :style="{backgroundImage: 'url(' + sevenImg + ')'}">
            <div class="one" :style="{backgroundImage: 'url(' + oneImg + ')'}" @click="jump(blog1)">{{array[1]}}</div>
            <div class="two" :style="{backgroundImage: 'url(' + twoImg + ')'}" onclick="jump(blog2)">{{array[2]}}</div>
            <div class="three" :style="{backgroundImage: 'url(' + threeImg + ')'}" onclick="jump(blog3)">{{array[3]}}</div>
            <div class="four" :style="{backgroundImage: 'url(' + fourImg + ')'}" onclick="jump(blog4)">{{array[4]}}</div>
            <div class="five" :style="{backgroundImage: 'url(' + fiveImg + ')'}" onclick="jump(blog5)">{{array[5]}}</div>
            <div class="seven" :style="{backgroundImage: 'url(' + sixImg + ')'}" onclick="jump(blog6)">{{array[6]}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Rotation",
        data(){
            return{
                oneImg:"",
                twoImg:"",
                threeImg:"",
                fourImg:"",
                fiveImg:"",
                sixImg:"",
                sevenImg:"",
                array:[],
                blog1:'',
                blog2:'',
                blog3:'',
                blog4:'',
                blog5:'',
                blog6:'',
            }
        },
        created(){
          this.findHomeImg()
        },
        methods:{
            async findHomeImg(){
                var findHomeImg = await this.$home.findHomeImg()
                var array = []
                var titles = []
                var ids = []
                for(let i in findHomeImg.data){
                    array.push(findHomeImg.data[i].photo)
                    titles.push(findHomeImg.data[i].title)
                    ids.push(findHomeImg.data[i].id)
                }
                this.array = titles
                this.oneImg = "http://img.fuqz.cc/"+array[0]
                this.twoImg = "http://img.fuqz.cc/"+array[1]
                this.threeImg = "http://img.fuqz.cc/"+array[2]
                this.fourImg = "http://img.fuqz.cc/"+array[3]
                this.fiveImg = "http://img.fuqz.cc/"+array[4]
                this.sixImg = "http://img.fuqz.cc/"+array[5]
                this.sevenImg = "http://img.fuqz.cc/"+array[6]

                this.blog1 = ids[1]
                this.blog2 = ids[2]
                this.blog3 = ids[3]
                this.blog4 = ids[4]
                this.blog5 = ids[5]
                this.blog6 = ids[6]
            },
            jump(id){
                this.$router.push({path:"/blogpostImg/"+id})
            }
        }
    }
</script>

<style scoped>

</style>
