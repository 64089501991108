<template>
    <!--:style="{backgroundImage: 'url('+images+')'}"-->
    <div class="headerImg">
        <!-- 背景图 -->
<!--        <img class="headBack" src="http://img.fuqz.cc/index-head.jpg" alt="">-->
        <div class="brief">
            <!-- 头像 -->
            <img class="headTou" src="http://img.fuqz.cc/radish.jpg" alt="">
            <!-- 名字 -->
            <div><span>十一</span></div>
            <!-- 标题 -->
            <p>欢迎来到FQZ客栈</p>
            <!-- 横线 -->
            <hr style="width: 50%;"/>
            <!-- 简介 -->
            <span class="introduce">“所有人都祝你快乐”</span>
            <span class="introduce">“我只愿你”</span>
            <span class="introduce">“遍历山河”</span>
            <span class="introduce">“觉得人间值得"</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "headerImg",
        data(){
            return{
                images:"http://img.fuqz.cc/113958-153525479855be.jpg", /* 首页图片 */
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
